<template>
  <div class="foot" v-if="showTabBar">
    <van-tabbar @change="onChange" v-model="active" active-color="#FF3F47" inactive-color="#999">
      <van-tabbar-item name="/course" icon="column">课程</van-tabbar-item>
      <van-tabbar-item name="/mine" icon="manager">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: "Foot",
  data() {
    return {
      active: '/course'
    }
  },
  mounted() {
    let path = this.$route.path
    if(path === '/') {
      path = '/course'
    }
    this.active = path
  },
  computed: {
    showTabBar() {
      let name = this.$route.name;
      // console.log('name', name)
      return (this.$route.name === '' || this.$route.name === null || this.$route.name === 'Course' || this.$route.name === 'Mine' )
    }
  },
  methods: {
    onChange(e) {
      this.$router.push({path:e})
    }
  },
  watch: {
    '$route': {
      handler() {
        let path = this.$route.path
        if(path === '/') {
          path = '/course'
        }
        this.active = path
      },
      deep: true,
    }
  }
}
</script>
<style lang="less">

</style>
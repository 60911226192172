<template>
  <div>
    <div class="title">
      <p>登录</p>
    </div>
    <div class="form">
      <div class="input-box">
        <div class="user-icon"><img src="@/assets/images/h5-icon/user.png"></div>
        <input class="input" v-model="loginForm.phone" placeholder="请输入手机号" type="text">
      </div>
      <div class="input-box">
        <div class="pwd-icon"><img src="@/assets/images/h5-icon/pwd.png"></div>
        <input class="input" v-model="loginForm.password" placeholder="请输入密码" :type="pwd1show ? 'text' : 'password'">
        <div class="toggle-password" @click="pwd1show = !pwd1show">
          <img v-if="!pwd1show" src="@/assets/images/h5-icon/pwd-1.png" alt="">
          <img v-if="pwd1show" src="@/assets/images/h5-icon/pwd-2.png" alt="">
        </div>
      </div>
    </div>
    <div class="button-box">
      <span  @click="$emit('toggletype',1)">手机短信登录</span>
      <span  @click="$emit('toggletype',3)" >忘记密码</span>
    </div>
    <button class="confirm" @click="loginByPassword">登录</button>
    <div class="no-account">还没有账号？<span @click="$emit('toggletype',2)">立即注册</span></div>
    <!-- <div class="protocol">
      <img src="@/assets/images/h5-icon/icon-1.png" alt="">
      <span>已阅读并同意软件服务协议及隐私政策</span>
    </div> -->
  </div>
</template>

<script>
import GetVsCode from '@/components/user/GetVsCode'
import { loginByPassword } from '@/api/api'
export default {
  name: "LoginPwd",
  data() {
    return {
      pwd1show: false,
      vcodeImg: null,
      loginForm: {
        phone: '',
        password: '',
      },
    }
  },
  components: {
    GetVsCode
  },
  methods: {
    checkForm() {
      let loginForm = this.loginForm
      if(loginForm.phone === '') {
        this.$toast.fail('手机号码不能为空');
        return false
      }
      if(loginForm.password === '') {
        this.$toast.fail('密码不能为空');
        return false
      }
      if(!/^1[3456789]\d{9}$/.test(loginForm.phone)) {
        this.$toast.fail('手机号码格式不正确');
        return false
      }
      
      return true
    },
    hideLoginDialog() {
      this.$store.dispatch('toggleDialog', false)
    },
    async loginByPassword() {
      if(!this.checkForm()) {
        return false
      }
      let params = this.loginForm
      let response = await loginByPassword(params)

      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        // this.createImageCode();
        return false
      }
      this.$toast(response.returnMsg)
      this.setUserInfo(response.data)
      this.hideLoginDialog()
      this.$nextTick(() => {
        window.location.reload()
      })
    },
    setUserInfo(val = null) {
      this.$store.dispatch("setUserInfo", val)
    }
  }
}
</script>
<style lang="less" scoped>
.title{
  font-size: 18px;
  color:#333;
  font-weight: bold;
  padding:0.2rem 0;
}
.form{
  margin: 0.2rem 0;
}
.button-box{
  height: 0.6rem;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
  color: #999999;
  justify-content: space-between;
  margin-top: 0.3rem;
} 
.button-box span{
  cursor: pointer;
}
.input-box{
  border-bottom: 1px solid #FF3F47;
  // height:  0.65rem;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.1rem;
  font-size: 14px;
  
}
.input-box .user-icon{
  width:0.32rem;
  height:0.34rem;
  margin-right: 0.2rem;
}
.input-box .pwd-icon{
  width:0.3rem;
  height:0.34rem;
  margin-right: 0.2rem;
}
.input-box .user-icon img,.input-box .pwd-icon img{
  width:100%;
  height: 100%;
}
.input-box .input{
  width: 100%;
  height:  0.64rem;
  line-height:  0.64rem;
  flex-grow: 1;
  flex-shrink: 1;
  border:none;
  outline: none;
}
.confirm{
  width: 98%;
  height:0.8rem;
  line-height: 0.8rem;
  background: #FF3F47;
  border-radius: 6px;
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  border:none;
}
.toggle-password{
  width: 0.4rem;
  height: 0.24rem;
  cursor: pointer;
  
}
.toggle-password img{
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.button-box{
  height: 0.6rem;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
  color: #999999;
  justify-content: space-between;
  
}
.button-box span{
  cursor: pointer;
  color:#666;
}
.no-account{
  margin-top: 0.5rem;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.no-account span{
  color: #FF3F47;
}
.protocol{
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #6F6F6F;
  cursor: pointer;
  margin-top: 0.2rem;
  align-items: center;
  
}
.protocol img{
  display: block;
  width: 0.28rem;
  height: 0.28rem;
  margin-right: 12px;
}    
//  .tab{
//   display: flex;
//   justify-content: space-around;
//   height: 32px;
//   line-height: 32px;
//   font-size: 16px;
//   font-weight: 400;
//   color: #999999;
//   text-align: center;
//   margin-bottom: 20px;
//   .tab-list{
//     border-bottom: 2px solid #fff;
//     width: 50px;
//     cursor: pointer;
//   }
//   .tab-list-active{
//     border-bottom: 2px solid #FF3F47;
//     color: #333333;
//     font-weight: bold;
//   }
// }
// .form{
//   margin-bottom: 25px;
// }
// .button-box{
//   height: 58px;
//   display: flex;
//   align-items: center;
//   padding: 0 5px;
//   font-size: 14px;
//   color: #999999;
//   justify-content: space-between;
//   span{
//     cursor: pointer;
//   }
// }
// .input-box{
//   border-bottom: 1px solid #E8E8E8;
//   height: 58px;
//   display: flex;
//   align-items: center;
//   padding: 0 5px;
//   font-size: 14px;
//   .input{
//     width: 100%;
//     height: 58px;
//     line-height: 58px;
//     flex-grow: 1;
//     flex-shrink: 1;
//   }
// }
// .confirm{
//   width: 370px;
//   height: 49px;
//   line-height: 49px;
//   background: #FF3F47;
//   border-radius: 6px;
//   font-size: 21px;
//   // font-weight: bold;
//   color: #FFFFFF;
//   text-align: center;
// }
// .vcode{
//   width: 90px;
//   height: 23px;
//   background: #eee;
//   cursor: pointer;
//   img{
//     width: 100%;
//     height: 100%;
//     vertical-align: top;
//   }
// }
// .toggle-password{
//   width: 20px;
//   height: 12px;
//   cursor: pointer;
//   img{
//     width: 100%;
//     height: 100%;
//     vertical-align: top;
//   }
// }
// .has-account{
//   color: #FF3F47;
//   margin-top: 34px;
//   text-align: center;
//   font-size: 19px;
//   cursor: pointer;
// }
// .protocol{
//   display: flex;
//   justify-content: center;
//   font-size: 16px;
//   font-weight: 500;
//   color: #6F6F6F;
//   cursor: pointer;
//   margin-top: 20px;
//   img{
//     display: block;
//     width: 22px;
//     height: 22px;
//     margin-right: 12px;
//   }
// }
</style>

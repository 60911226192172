<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2022-02-11 09:16:20
 * @LastEditTime : 2022-03-31 11:18:34
 * @LastEditors  : BigBigger
-->
<template>
	<van-uploader
		v-model="fileList"
		:after-read="handleUpload"
		:maxCount="maxCount"
    @delete="changeValue"
		v-bind="$attrs"
	>
	</van-uploader>
</template>

<script>
import { upload } from '@/api/api';

export default {
	name: 'VUpload',
	model: {
		prop: 'value',
		event: 'change',
	},
	props: {
		value: [String, Array],
		data: Object,
		maxCount: {
			type: Number,
			default: 1,
		},
		toStr: {
			type: Boolean,
			default: true,
		},
		maxSize: {
			type: Number,
			default: Number.MAX_SAFE_INTEGER,
		}
	},
	data() {
		return {
			fileList: [],
		};
	},
	watch: {
		value: {
			handler(newValue, oldValue) {
				if (JSON.stringify(oldValue) === JSON.stringify(newValue))
					return;
        if (!newValue)  {
					this.fileList = [];
					return;
				}
				if (typeof newValue === 'string') {
					this.fileList = [{ url: newValue }];
					return;
				}
				if (!Array.isArray(newValue)) {
					this.fileList = [];
					return;
				}
				this.fileList = newValue.map((url) => ({ url }));
			},
			immediate: true,
			deep: true,
		},
	},
	methods: {
		handleUpload(file) {
			const checkType = /image\/.*/.test(file.file.type);
			if (!checkType) {
				this.$toast('请上传图片');
				file.status = 'error';
				return this.changeValue();
			}
			const checkSize = file.file.size <= this.maxSize;
			if (!checkSize) {
				this.$toast('文件过大');
				file.status = 'error';
				return this.changeValue();
			}
      file.status = 'uploading';
      file.message = '上传中...';
			upload({ file: file.file, data: this.data })
				.then((res) => {
					file.status = 'success';
          file.message = '上传成功！';
					file.url = res.data.src;
					this.changeValue();
				})
				.catch((err) => {
					file.status = 'error';
          file.message = '上传失败！';
					this.changeValue();
				});
		},
		changeValue() {
			const _fileList = this.fileList
				.filter((i) => i.status !== 'error')
				.map((i) => i.url);
			this.$emit(
				'change',
				this.maxCount === 1 && this.toStr ? _fileList[0] : _fileList,
			);
		},
	},
};
</script>

<style lang="less" scoped>
::v-deep .van-uploader__wrapper {
	margin-top: 8px;
}
::v-deep .van-loading__spinner {
	vertical-align: top;
}
</style>

/*
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2022-11-02 11:15:19
 * @LastEditors  : BigBigger
 */
export const TIME_OUT = 10000;

export const LOOP_TIME = 60000;

export const LIVE_STATUS = {
  1: '暂无直播',
  2: '直播中',
  3: '点播',
  4: '视频生成中',
  5: '回放',
}

export const JOB_TYPE_QUERY = [
  {name: '部门名称', key:'DEPARTMENTNAME'},
  {name: '单位名称', key:'EMPLOYER'},
  {name: '职位名称', key:'POSITIONNAME'},
  {name: '职位代码', key:'JOBCODE'},
]

export const SEX = {
  1: '男',
  2: '女'
}

export const THROUGH_PAY = {
  1: '0元上课，上岸后缴费',
  0: '先缴费'
}

export const STUDENT = {
  1: '新学员',
  2: '老学员'
}

export const FRESHSTUDENT = {
  1: '应届',
  2: '往届'
}

export const ORDERSTATE = {
  1: '未支付',
  2: '已支付',
  3: '取消订单'
}

export const CONFIRMSTATE = {
  1: '待审核',
  2: '已审核',
  3: '等待',
  4: '拒绝',
  5: '退班',
}

export const PAYSTATE = {
  1: '未支付',
  2: '已支付',
}

export const PAYWAY = {
  1: '全款',
  2: '定金',
}

export const BTNSTATEFILTER = (item) => {
  let obj = {
    state: '',
    canClick: false,
    type:0, // 0购买 1审核
  }
  let state = '', canClick = false,type = 0;
  if (!item) item = {};
  if(item.whetherReation === 1) {
    // 配套课程
    if(item.buyFlag === 1) {
      // 是否购买
      state = '已购买'
    } else {
      state = '赠送课程'
    }
  } else {
    // 非配套课程
    if (item.interviewFlag === 1) {
      if (item.confirmStatus === 0) {
        if(item.whetherOpenPoint === 1) {
          state = '立即报名'
          canClick = true
        } else {
          state = '暂未开放'
          canClick = false
        }
      } else if (item.confirmStatus === 1) {
        state = '已报名(待审核)'
        canClick = true
        type = 1
      } else if (item.confirmStatus === 2) {
          if(item.orderStatus === 0) {
            if(item.whetherOpenPoint === 1) {
              state = '立即报名'
              canClick = true
            } else {
              state = '暂未开放'
              canClick = false
            }
          } else if(item.orderStatus === 1) {
            state = '待付款(已审核)'
            canClick = true
            type = 1
          } else if(item.orderStatus === 2)  {
            state = '已报名(已支付)'
          } else {
            if(item.whetherOpenPoint === 1) {
              state = '立即报名'
              canClick = true
            } else {
              state = '暂未开放'
              canClick = false
            }
          }
        // state = '已报名'
      } else if (item.confirmStatus === 3) {
        state = '已报名(等待审核)'
        canClick = true
        type = 1
      } else if (item.confirmStatus === 4) {
        state = '已拒绝'
        canClick = true
        type = 1
      } else if (item.confirmStatus === 5) {
        state = '已退班'
      } else {
        state = '立即报名'
        canClick = true
      }
    } else {
      if(item.buyFlag  === 1) {
        state = '已购买'
      } else {
        if(item.whetherOpenPoint === 1) {
          state = '立即报名'
          canClick = true
        } else {
          state = '暂未开放'
          canClick = false
        }
      }
    }
  }
  // if(item.buyFlag === 1) {
    // if (item.interviewFlag === 1) {
    //   if (item.confirmStatus === 0) {
    //     state = '立即购买'
    //     canClick = true
    //   } else if (item.confirmStatus === 1) {
    //     state = '已报名(待审核)'
    //     canClick = true
    //     type = 1
    //   } else if (item.confirmStatus === 2) {
    //       if(item.orderStatus === 0) {
    //         state = '立即购买'
    //         canClick = true
    //       } else if(item.orderStatus === 1) {
    //         state = '立即付款(已审核)'
    //         canClick = true
    //         type = 1
    //       } else if(item.orderStatus === 2)  {
    //         state = '已报名(已支付)'
    //       } else {
    //         state = '立即购买'
    //         canClick = true
    //       }
    //     // state = '已报名'
    //   } else if (item.confirmStatus === 3) {
    //     state = '已报名(等待审核)'
    //     canClick = true
    //     type = 1
    //   } else if (item.confirmStatus === 4) {
    //     state = '已拒绝'
    //     canClick = true
    //     type = 1
    //   } else if (item.confirmStatus === 5) {
    //     state = '已退班'
    //   } else {
    //     state = '立即购买'
    //     canClick = true
    //   }
    // } else {
    //   if(item.orderStatus === 2) {
    //     state = '已购买'
    //   } else {
    //     state = '立即购买'
    //     canClick = true
    //   }
    // }
  // } else {
  //   state = '立即购买'
  //   canClick = true
  // }
  obj.state = state;
  obj.canClick = canClick;
  obj.type = type
  return obj
}
export const MINIBTNSTATEFILTER = (item) => {
  let obj = {
    state: '',
    canClick: false,
    type:0, // 0购买 1审核
  }
  let state = '', canClick = false,type = 0;
  if(item.whetherReation === 1) {
    // 配套课程
    if(item.buyFlag === 1) {
      // 是否购买
      state = '已报名'
    } else {
      state = '赠送课程'
    }
  } else {
    // 非配套课程
    if (item.interviewFlag === 1) {
      if (item.confirmStatus === 0) {
        if(item.whetherOpenPoint === 1) {
          state = '立即报名'
          canClick = true
        } else {
          state = '暂未开放'
          canClick = false
        }
      } else if (item.confirmStatus === 1) {
        state = '已报名|(待审核)'
        canClick = true
        type = 1
      } else if (item.confirmStatus === 2) {
          if(item.orderStatus === 0) {
            if(item.whetherOpenPoint === 1) {
              state = '立即报名'
              canClick = true
            } else {
              state = '暂未开放'
              canClick = false
            }
          } else if(item.orderStatus === 1) {
            state = '待付款|(已审核)'
            canClick = true
            type = 1
          } else if(item.orderStatus === 2)  {
            state = '已报名|(已支付)'
          } else {
            if(item.whetherOpenPoint === 1) {
              state = '立即报名'
              canClick = true
            } else {
              state = '暂未开放'
              canClick = false
            }
          }
        // state = '已报名'
      } else if (item.confirmStatus === 3) {
        state = '已报名|(等待审核)'
        canClick = true
        type = 1
      } else if (item.confirmStatus === 4) {
        state = '已拒绝'
        // canClick = true
        type = 1
      } else if (item.confirmStatus === 5) {
        state = '已退班'
      } else {
        state = '立即报名'
        canClick = true
      }
    } else {
      if(item.buyFlag  === 1) {
        state = '已购买'
      } else {
        if(item.whetherOpenPoint === 1) {
          state = '立即报名'
          canClick = true
        } else {
          state = '暂未开放'
          canClick = false
        }
      }
    }
  }

  obj.state = state;
  obj.canClick = canClick;
  obj.type = type
  return obj
}
export const QUESTION_LIST = [
  {
    key:0,
    title: '报名渠道有哪些',
    content: '博学面试班只接受网上系统报名。可登陆官网，点击报名系统，注册报名。或关注微信公众号“博学教育”微信端口报名。笔试班可以接受网上系统报名和线下报名。'
  },
  {
    key:1,
    title: '如何选择报考职位',
    content: '注册登陆后，点击“开始报名”，选择班次--->报名--->职位搜索--->选择职位报名'
  },
  {
    key:2,
    title: '如何填写个人信息',
    content: '请如实填写，信息不正确会导致审核通不过，如果本门考试没有查分密码和排名请填“0”。'
  },
  {
    key:3,
    title: '工作人员在指定时间内完成审核',
    content: '审核专员会在2小时内完成审核，审核通过后系统短信通知学员，部分学员可能会收不到短信可登入系统查看，以系统显示状态为准。'
  },
  {
    key:4,
    title: '审核机制有哪些',
    content: `请准确填写学员信息，弄虚作假将不予审核，虚报岗位将不予退款。审核专员按照报名先后顺序进行审核。
    审核有三种结果：已审核、等待、拒绝。
    已审核：代表通过审核，您将会收到系统短信通知，可进行缴费等后续程序。
    等待：代表您所报的岗位有人排队，需要等待最多2小时。
    拒绝：代表您所报的岗位已经报满。`
  },
  {
    key:5,
    title: '缴费方式',
    content: '通过审核后，只能在系统内由支付宝支付全款或定金，请在2小时内缴费，超时将转为等待状态重新排序审核。缴纳定金的学员，余款在报到现场缴纳。'
  },
  {
    key:6,
    title: '缴费通知',
    content: '缴费成功后系统内缴费状态将会变更，说明报名已经成功。'
  },
  {
    key:7,
    title: '上课方式、内容',
    content: '博学面试班分为经典面试班、VIP特训班等。课程模式：理论集中授课+小组训练+模考组成，涉及面试题型、内容学习，语言表达与思维，心理训练、精神面貌及气质类型，形象设计，全真模拟考试，临场注意事项，权威考前预测。'
  },
  {
    key:8,
    title: '上课地点',
    content: '杭州准四星级酒店（详见简章）。'
  },
]

export const FORM_RULES = {
  phone: [
    { required: true, message: '请填写手机号', trigger: 'change' }
  ],
}

export const CITIES = [
  { title: '江苏', value: 1, url: 'js' },
  { title: '山东', value: 3, url: 'sd' },
  { title: '安徽', value: 2, url: 'ah' },
]
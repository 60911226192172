<template>
  <div class="nav">
    <LoginDialog></LoginDialog>
  </div>
</template>
<script>
import LoginDialog from '@/components/user/LoginDialog'
// import { logout } from '@/api/api'
export default {
  name: "Nav",
  components: {
    LoginDialog
  },
  computed: {
    getUserInfo() {
      if(this.$store.state.user) {
        return this.$store.state.user.userInfo || null
      } else {
        return null
      }
    }
  },
  methods: {
    jump(url) {
      window.location.href=url
    },
    navClick(val) {
      this.$router.push({path:val})
      // window.location.href = val
    },
    login() {
      this.$store.commit('login/showDialog')
    },
    mineClick(val) {
      this.$router.push({path:`/mine`,query:{
        type: val
      }})
    },
    handleCommand(val) {
      switch (val) {
        case 'logout':
          this.logout()
          break;

        case 'myCourse':
          this.mineClick(0)
          break;

        case 'myOrders':
          this.mineClick(1)
          break;

        case 'courseProtocol':
          this.mineClick(2)
          break;

        case 'myInfo':
          this.mineClick(4)
          break;
      
        default:
          break;
      }
    },
    async logout() {
      let response = await logout({})
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.$store.commit('user/logout')
      this.$toast('退出成功')
      this.$nextTick(() => {
        window.location.reload()
      })
    }
  },
  filters: {
    phoneFilter(val) {
      if(val) {
        return `${val.substr(0,3)}****${val.substr(7)}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>

</style>
<template>
  <div class="vcodebtn" @click="btnClick">{{getText}}</div>
</template>

<script>
import { createPhoneCode } from '@/api/api'
export default {
  name: "GetVsCode",
  props: {
    time: {
      type: Number,
      default: 60
    },
    phone: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timeid: null,
      showTime: 0,
      canClick: true,
    }
  },
  mounted() {
    // this.resetTime()
  },
  beforeDestroy() {
    clearInterval(this.timeid)
  },
  computed: {
    getText() {
      let text = '获取验证码'
      if(this.showTime > 0) {
        text = `${this.showTime}S后重新获取`
      } else {
        text = '获取验证码'
      }
      return text
    }
  },
  methods: {
    // 发送手机验证码
    async createPhoneCode() {
      if(!this.phone) {
        this.$toast('手机号不能为空')
        return false
      }
      let params = {
        phone: this.phone
      }
      let response = await createPhoneCode(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.$toast(response.returnMsg)
      this.countDown()
    },
    resetTime() {
      this.showTime = this.time
    },
    btnClick() {
      if(this.canClick) {
        this.createPhoneCode()
        
      }
    },
    countDown() {
      this.resetTime()
      this.timeid = setInterval(() => {
        if(this.showTime > 0) {
          this.showTime -= 1
          this.canClick = false
        } else {
          this.canClick = true
          clearInterval(this.timeid)
          // this.resetTime()
        }
      },1000)
    },
  }
}
</script>

<style lang="less" scoped>
.vcodebtn{
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 400;
  color: #FF3F47;
  cursor: pointer;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  user-select: none;
}
</style>
<template>
  <div>
    <div class="shade"  v-if="loginDialogIsShow">
      <div class="content">
        <p class="close"  @click="hideLoginDialog">
          <i class="close-btn">
            <img src='@/assets/images/h5-icon/close.png'>
          </i>
        </p>
        <LoginPwd @toggletype="toggleType" v-if="type == 0" />
        <LoginCode @toggletype="toggleType" v-if="type == 1" />
        <Regist @toggletype="toggleType" v-if="type == 2" />
        <FindPwd @toggletype="toggleType" v-if="type == 3" />
      </div>
    </div>
  </div>
</template>

<script>
import LoginPwd from '@/components/user/LoginPwd'
import LoginCode from '@/components/user/LoginCode'
import Regist from '@/components/user/Regist'
import FindPwd from '@/components/user/FindPwd'
export default {
  name: "LoginDialog",
  data() {
    return {
      type: -1, // 0: 密码登录； 1： 验证码登录； 2：注册； 3：忘记密码；
      showDialog: false
    }
  },
  components: {
    LoginPwd,
    LoginCode,
    Regist,
    FindPwd
  },
  mounted() {
    // this.showDialog = this.$store.state.login.loginDialogIsShow || false
  },
  computed: {
    loginDialogIsShow() {
      return this.$store.state.loginDialogIsShow || false
    }
  },
  watch: {
    loginDialogIsShow(val) {
      if(val) {
        this.type = 0
      }
    }
  },
  methods: {
    toggleType(val) {
      this.type = val
    },
    hideLoginDialog() {
      this.$store.dispatch('toggleDialog', false)
    }
  }
}
</script>

<style lang="less" scoped>
.shade{
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 90;
  background: rgba(0,0,0,0.5);
}
.close{
  width: 0.6rem;
  height: 0.6rem;
  line-height: 50px;
  position: absolute;
  top: 0.3rem;
  right: 3%;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #999;
  text-align: center;
}

.close-btn img{
  width:0.3rem;
  height:0.3rem;
  margin:0.15rem 0;
}
.content{
  width: 80%;
  border-radius: 10px;
  padding:0.2rem 5% 0.5rem 5%;
  background: #fff;
  background-size: 130px auto;
  position: relative;
}
</style>
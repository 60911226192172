<!--侧边栏-->
<template>
  <div class="sideBox" v-if="!hideSide">
    <ul>
      <li class="index" @click="jumpClick('/course')">
        <i></i>
        <span>首页</span>
        
      </li>
      <li class="mine" @click="jumpClick('/mine')">
        <i></i>
        <span>我的</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  methods:{
    jumpClick(val) {
      if(this.$route.path !== val) {
        this.$router.push({path: val})
      }
    },
  },
  computed: {
    hideSide() {
      let name = this.$route.name;
      return ( this.$route.name === 'Course' || this.$route.name === 'Mine' || this.$route.name === 'Play' || this.$route.name === 'Mini')
    }
  }
}
</script>
<style lang="less" scoped>
.sideBox{
  position: fixed;
  right:0px;
  top:53%;
}
.sideBox ul li{
  // width: 0.95rem;
  // height: 0.95rem;
  // background:rgba(0,0,0,0.1);
  // border-radius:50%;
  // margin:0 auto;
  // text-align: center;
  border: 1px solid #efefef;
  text-align: center;
  background-color: rgba(0,0,0,.3);
  padding:0.05rem;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  i{
    display: block;
    
  }
  span{
    display: block;
    color: #fff;
    font-size: 12px;
  }
}
.sideBox ul .mine{
  margin-top: 0.1rem;
}
.sideBox ul .index i{
  width:0.32rem;
  height:0.32rem;
  margin:0.05rem 0.1rem;
  background:url('../../assets/images/layout/home.png') no-repeat center;
  background-size: 100% 100%;
}
.sideBox ul .mine i{
  width:0.32rem;
  height:0.32rem;
  margin:0.05rem 0.1rem;
  background:url('../../assets/images/layout/mine.png') no-repeat center;
  background-size: 100% 100%;
}


</style>
import { render, staticRenderFns } from "./Regist.vue?vue&type=template&id=6f09c73e&scoped=true&"
import script from "./Regist.vue?vue&type=script&lang=js&"
export * from "./Regist.vue?vue&type=script&lang=js&"
import style0 from "./Regist.vue?vue&type=style&index=0&id=6f09c73e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f09c73e",
  null
  
)

export default component.exports